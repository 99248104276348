// Colors
$color-white: #ffffff;
$color-black: #000000;

$color-gray-lightest: #f9f9f9;
$color-gray-lighter: #e2e2e2;
$color-gray-light: #dddddd;
$color-gray: #cccccc;
$color-gray-darkest: #2d2d2c;

$color-green: #8fd239;
$color-red: #d33138;
$color-yellow: #efb726;
$color-blue: #3da5d9;



// Special colors
$color-xml: $color-yellow;
$color-json: $color-blue;
$color-excel: $color-green;

$color-mandatory: #F0643A;
$color-recommended: $color-blue;
$color-unique: $color-yellow;
$color-unmapped: $color-green;
