html, html:before, html:after, html * {
  box-sizing: border-box;
}

body {
  color: #777;
  background-color: #eee;
  background-image: url("signin_bg.b8c792b2.jpg");
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  padding-top: 0 !important;
}

.login__error-message {
  color: #fff;
}

.login-wrapper {
  text-align: center;
}

.login {
  text-align: left;
  display: inline-block;
}

.login__logo {
  max-height: 70px;
  max-width: 200px;
  width: auto;
  height: auto;
  margin-top: 166px;
  margin-bottom: 30px;
  display: block;
}

.login__title {
  color: #000;
  clear: both;
  text-indent: 0;
  margin: 0 0 10px;
  font-size: 25px;
  font-weight: bold;
}

.login__submit-button {
  width: 100%;
  height: 35px;
  color: #fff;
  cursor: pointer;
  background-color: #284081;
  border: 0;
  margin-right: 5px;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 35px;
  display: block;
}

.login__submit-button:disabled {
  cursor: default;
  background: gray;
}

.login__submit-button:disabled:hover {
  opacity: 1;
}

.login__submit-button:hover {
  opacity: .7;
}

.login__email-input {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 15px;
  line-height: normal;
  display: block;
}

.login__form-wrapper {
  background-color: #fff;
  padding: 25px 35px 24px;
}

.login__privacy-policy {
  padding-top: 14px;
  font-size: 10px;
}

.login-form {
  min-width: 304px;
}

.login__redirect-message {
  color: #fff;
}

/*# sourceMappingURL=login.css.map */
