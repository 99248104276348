﻿// Sass thingies
@import "utils/colors";

// Sane box-sizing
html {
    box-sizing: border-box;
    &::before,
    &::after,
    & * {
        box-sizing: border-box;
    }
}

body {
    background-color: #eeeeee;
    padding-bottom: 40px;
    padding-top: 40px;
    font-family: 'Lato', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #777;
    background-image: url('../images/signin_bg.jpg');
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    padding-top: 0 !important;
}

// Login page styling
.login__error-message {
    color: #fff;
}

.login-wrapper {
    text-align: center;
}

.login {
    text-align: left;
    display: inline-block;
}

.login__logo {
    max-height: 70px;
    max-width: 200px;
    width: auto;
    height: auto;
    margin-top: 166px;
    margin-bottom: 30px;
    display: block;
}

.login__title {
    font-size: 25px;
    font-weight: bold;
    color: $color-black;
    clear: both;
    text-indent: 0;
    margin: 0 0 10px 0;
}

.login__submit-button {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    color: $color-white;
    font-size: 20px;
    font-weight: bold;
    background-color: #284081;
    border: 0;
    padding: 0 10px;
    margin-right: 5px;
    cursor: pointer;

    &:disabled {
        cursor: default;
        background: gray;

        &:hover {
            opacity: 1;
        }
    }

    &:hover {
        opacity: 0.7;
    }
}

.login__email-input {
    display: block;
    border: 1px solid #cccccc;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 15px;
    line-height: normal;
}

.login__form-wrapper {
    background-color: rgba(255, 255, 255, 1);
    padding: 25px 35px 24px 35px;
}

.login__privacy-policy {
    padding-top: 14px;
    font-size: 10px;
}

.login-form {
    min-width: 304px;
}

.login__redirect-message {
    color: #fff;
}